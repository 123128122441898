@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Marske';
    src: url('/public/assets/fonts/Mylodon-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Loader.css */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.mosh-loader {
    width: 100px;
    animation: moshAnimation 2s linear infinite;
}

@keyframes moshAnimation {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0);
    }
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Marske', sans-serif;
}

html, body {
    height: 100%;
    overflow: hidden;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    margin: 0;
    overflow: hidden;
}

section {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
}

section::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000, #4f4f4f, #000);
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(100%);
    }
}

section .signin .content img.mosh-logo {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.9);
}


section span {
    position: relative;
    display: block;
    width: calc(6.25vw - 2px);
    height: calc(6.25vw - 2px);
    background: #181818;
    z-index: 2;
    transition: 1.5s;
}

section span:hover {
    background: #707070;
    transition: 0s;
}

section .signin {
    position: absolute;
    width: 80%;
    max-width: 500px;
    background: #222;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.9);
    height: 100%;
}

section .signin .content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

section .signin .content h2 {
    font-size: 2.5em;
    color: #ffffff;
    text-transform: uppercase;
}

section .signin .content .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

section .signin .content .form .inputBox {
    position: relative;
    width: 100%;
}

section .signin .content .form .inputBox input {
    position: relative;
    width: 100%;
    background: #333;
    border: none;
    outline: none;
    padding: 25px 10px 7.5px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 1em;
}

section .signin .content .form .inputBox button {
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    background: #FFF200FF;
    color: #000;
    font-weight: 600;
    font-size: 1.35em;
    letter-spacing: 0.05em;
    cursor: pointer;
    border: none;
}

section .signin .content .form .inputBox button:active {
    opacity: 0.6;
}


section .signin .content .form .inputBox i {
    position: absolute;
    left: 0;
    padding: 15px 10px;
    font-style: normal;
    color: #aaa;
    transition: 0.5s;
    pointer-events: none;
}

.signin .content .form .inputBox input:focus ~ i,
.signin .content .form .inputBox input:valid ~ i {
    transform: translateY(-7.5px);
    font-size: 0.8em;
    color: #fff;
}

.signin .content .form .links {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.signin .content .form .links p {
    color: #fff;
    text-align: center;
}

.signin .content .form .links a {
    color: #fff200;
    text-decoration: none;
    font-weight: 600;
}

.login-text {
    display: inline-block;
    margin-top: 10px;
}

.signin .content .form .links a {
    color: #fff;
    text-decoration: none;
}

.signin .content .form .links a:nth-child(2) {
    color: #fff200;
    font-weight: 600;
}

.signin .content .form .inputBox input[type="submit"] {
    padding: 10px;
    background: #FFF200FF;
    color: #000;
    font-weight: 600;
    font-size: 1.35em;
    letter-spacing: 0.05em;
    cursor: pointer;
}

input[type="submit"]:active {
    opacity: 0.6;
}

@media (max-width: 900px) {
    section span {
        width: calc(10vw - 2px);
        height: calc(10vw - 2px);
    }

    section .signin .content .form .inputBox input {
        padding: 15px 10px 7.5px;
        font-size: 1.2em;
    }

    section .signin .content .form .inputBox i {
        padding: 15px 10px;
    }

    .signin .content .form .inputBox input[type="submit"] {
        padding: 15px;
        font-size: 1.5em;
    }
}


@media (max-width: 600px) {
    * {
        font-size: 13px;
    }

    section .signin .content .form {
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    section .signin .content img.mosh-logo {
        width: 300px;
    }

    section span {
        width: calc(20vw - 2px);
        height: calc(20vw - 2px);
    }

    section .signin .content .form .inputBox input {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        background: #333;
        border: none;
        outline: none;
        padding: 25px 10px 7.5px;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        font-size: 1em;
    }
}